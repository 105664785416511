// extracted by mini-css-extract-plugin
export var link = "project-module--link--dJ8IJ";
export var linkContainer = "project-module--linkContainer--QdVfE";
export var mainContainer = "project-module--mainContainer--e2q3M";
export var portfolioLink = "project-module--portfolioLink--+Vm06";
export var portfolioLinkContainer = "project-module--portfolioLinkContainer--MaJzx";
export var portfolioLinkWrapper = "project-module--portfolioLinkWrapper--9IfGt";
export var projectTitle = "project-module--projectTitle--jQbz9";
export var slider = "project-module--slider---Is4B";
export var svgLine = "project-module--svgLine--5FtOy";
export var wrapper = "project-module--wrapper--xU2sY";